import React, { useState } from "react";
import "../styling/templates.css";

function App() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append(
      "subject",
      formData.get("subject") || "ECO Spindles - Become a Collector Form"
    );
    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      if (res.ok) {
        const response = await res.json();
        if (response.success) {
          // Set the success message and redirect
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage(""); // Clear any previous error message
          window.location.href = formData.get("redirect");
        } else {
          setErrorMessage("Form submission failed. Please try again.");
          setSuccessMessage(""); // Clear any previous success message
        }
      } else {
        setErrorMessage("Form submission failed. Please try again.");
        setSuccessMessage(""); // Clear any previous success message
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setSuccessMessage(""); // Clear any previous success message
    }
  };

  return (
    <div style={{ padding: "50px", paddingTop: "0px" }}>
      <form onSubmit={onSubmit}>
        <p className="topic-form">Supplier's Name:</p>
        <input
          type="text"
          name="Supplier's Name:"
          required
          className="inputStyle"
        />
        <br />
        <p className="topic-form">Address:</p>
        <input type="text" name="Address:" required className="inputStyle" />
        <br />
        <p className="topic-form">District:</p>
        <input type="text" name="District" required className="inputStyle" />
        <br />
        <p className="topic-form">Contact No:</p>
        <input type="text" name="Contact No:" required className="inputStyle" />
        <br />
        <p className="topic-form">Date Registered:</p>
        <input
          type="date"
          name="Date Registered:"
          required
          className="inputStyle"
        />
        <br />
        <p className="topic-form">ID no:</p>
        <input type="text" name="ID no:" required className="inputStyle" />
        <br />
        <p className="topic-form">Storing facility (Sqft.):</p>
        <input
          type="text"
          name="Storing facility (Sqft.):"
          required
          className="inputStyle"
        />
        <br />
        <p className="topic-form">Present collection QTY/ (KG):</p>
        <input
          type="text"
          name="Present collection QTY/ (KG):"
          required
          className="inputStyle"
        />
        <br />
        <p className="topic-form">Expected collection during upcoming years:</p>
        <input
          type="text"
          name="Expected collection during upcoming years:"
          required
          className="inputStyle"
        />
        <br />
        <p className="topic-form">
          Number of years in business relationship with Beira:
        </p>
        <input
          type="text"
          name="Number of years in business relationship with Beira:"
          required
          className="inputStyle"
        />
        <br />
        <p className="topic-form">Other Remarks:</p>
        <textarea
          name="Other Remarks:"
          rows="9"
          required
          className="textareaStyle"
        ></textarea>
        <br />
        <input type="hidden" name="ccemail" value="info@ecospindles.com; admin@3cs.lk" />
        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />
        <div className="form-footer">
          <button type="submit" className="btn submitButton btn-lg" id="button">
            Submit
          </button>
          <br />
          <br />
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      </form>
    </div>
  );
}

export default App;
